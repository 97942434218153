import { post } from '@rails/request.js';

const transloaditVirusScanParams = {
  auth: {
    key: process.env.TRANSLOADIT_AUTH_KEY,
  },
  template_id: process.env.TRANSLOADIT_VIRUS_SCAN_TEMPLATE_ID,
};

export default async function virusScanFile(file) {
  try {
    const signatureResponse = await getVirusScanSignature();

    const params = {
      ...transloaditVirusScanParams,
      auth: {
        ...transloaditVirusScanParams.auth,
        expires: signatureResponse.expires,
      },
    };

    const assemblyData = await createAssembly(file, params, signatureResponse.signature);
    await streamAssembly(assemblyData);
    const assemblyResult = await getAssemblyResult(assemblyData.assembly_id);
    if (assemblyResult.error) {
      throw new Error(assemblyResult.error);
    }

    return {
      status: 'success',
      message: 'Virus scan passed',
      results: assemblyResult.results,
      assembly_id: assemblyResult.assembly_id,
      assembly_url: assemblyResult.assembly_url,
    };
  } catch (error) {
    return {
      status: 'error',
      message: 'Virus scan failed',
      error: error.message,
    };
  }
}

async function createAssembly(file, params, signature) {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('params', JSON.stringify(params));
  formData.append('signature', signature);

  return fetch('https://api2.transloadit.com/assemblies', {
    method: 'POST',
    body: formData,
  }).then((response) => response.json())
    .then((data) => data);
}

function streamAssembly(assemblyData) {
  return new Promise((resolve, reject) => {
    const stream = new EventSource(assemblyData.update_stream_url);

    stream.addEventListener('message', (event) => {
      if (event.data === 'assembly_finished') {
        stream.close();
        resolve();
      }
    });

    stream.addEventListener('assembly_error', (event) => {
      reject(event.data);
    });
  });
}

function getAssemblyResult(assemblyId) {
  return fetch(`https://api2.transloadit.com/assemblies/${assemblyId}`)
    .then((response) => response.json())
    .then((data) => data);
}

async function getVirusScanSignature() {
  const response = await post('/transloadit/signatures');
  return response.json;
}
