import { Controller } from 'stimulus';

export default class extends Controller {
  static outlets = ['copy-to'];

  static targets = ['item'];

  copy() {
    if (this.hasCopyToOutlet) {
      const toTargets = this.copyToOutlet.itemTargets;
      this.itemTargets.forEach((from) => {
        const to = toTargets.find((s) => from.id === s.dataset.fromId);
        if (to) {
          to.textContent = from.value;
        } else {
          console.error(`Failed to find copy_to for ${from.dataset.fromId}`);
        }
      });
    }
  }
}
